import VerticalLine_ClassicVerticalSolidLineComponent from '@wix/thunderbolt-elements/src/components/VerticalLine/viewer/skinComps/BaseVerticalLine/ClassicVerticalSolidLine.skin';


const VerticalLine_ClassicVerticalSolidLine = {
  component: VerticalLine_ClassicVerticalSolidLineComponent
};


export const components = {
  ['VerticalLine_ClassicVerticalSolidLine']: VerticalLine_ClassicVerticalSolidLine
};

